import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  > main {
    flex: 1;

    margin-left: 80px;
  }
`;
